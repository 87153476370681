<template>
  <div>
    <ed-cliente :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdCliente from "@/components/log/visao";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdCliente },
  mounted() {
  },
  beforeDestroy() {
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
