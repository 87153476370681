<template>
  <div>
    <ed-modal
      v-model="bind.boolExibirDadosAlterados"
      width="100%"
      v-if="formData"
    >
      <div slot="title">Dados Alterados Log :: {{ formData._id }}</div>

      <div class="" slot="content">
        <pre>{{ formData.strDadosAlterados }}</pre>
      </div>
    </ed-modal>

    <ed-table
      :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @search="getLog"
      @filter="getLog"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
    >
      <template slot="table-filter">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.strNomeDatabase"
          label="Cliente"
          name="strNomeDatabase"
          item-text="strNome"
          item-value="strNomeDatabase"
          route="System/Cliente"
          :filters="{}"
          clearable
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Base Interna"
          name="strSessao"
          v-model="formFiltros.strNomeDatabase"
          :items="[
            { intId: 'admin', strNome: 'Admin' },
            { intId: 'retaguarda', strNome: 'Retaguarda' },
          ]"
          clearable
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data de Cadastro"
          name="strDataCadastro"
          v-model="formFiltros.strDataCadastro"
          clearable
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Sessão"
          name="strSessao"
          v-model="formFiltros.strSessao"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Mensagem"
          name="strMensagem"
          v-model="formFiltros.strMensagem"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Dados Alterados"
          name="strDadosAlterados"
          v-model="formFiltros.strDadosAlterados"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Tipo"
          name="strSessao"
          v-model="formFiltros.strTipo"
          :items="[
            { intId: 'info', strNome: 'Info' },
            { intId: 'error', strNome: 'Error' },
            { intId: 'debug', strNome: 'Debug' },
          ]"
          clearable
        />

        <!-- <ed-input-checkbox
          class="col-mr-2"
          style="float: left; width: auto"
          v-model="formFiltros.boolLimparCache"
          name="boolLimparCache"
          label="Limpar Cache"
        /> -->
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputText,
  EdInputDate,
  EdInputSelect,
  EdInputCheckbox,
  EdButton,
} from "@/components/common/form";
export default {
  name: "EdModelo",
  props: {},
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputText,
    EdInputDate,
    EdInputSelect,
    EdInputCheckbox,
    EdButton,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolExibirDadosAlterados: false,
      },
      loading: false,
      arrayHeaders: [
        {
          text: "Base",
          sortable: true,
          value: "strNomeDatabase",
        },
        {
          text: "IP",
          sortable: true,
          value: "strEnderecoIp",
        },
        {
          text: "Sessão",
          sortable: true,
          value: "strSessao",
        },
        {
          text: "Tipo",
          sortable: true,
          value: "strTipo",
        },
        {
          text: "Usuario",
          sortable: true,
          value: "intUsuarioId",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
        {
          text: "Mensagem",
          sortable: true,
          collapse: 50,
          value: "strMensagem",
        },
        // {
        //   text: "Dados Alterados",
        //   sortable: true,
        //   collapse:1,
        //   collapseLabel:'Visualizar',
        //   value: "strDadosAlterados",
        // },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        boolLimparCache: 0,
        strNomeDatabase: null,
        strDadosAlterados: null,
        strMensagem: null,
        strDataCadastro: this.$utilidade.toDate(null, true),
        strTipo: null,
        strSessao: null,
      },
      formData: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.getLog();
    },

    getDadosAlterados(item) {
      this.formData = Object.assign(item, {});
      this.bind.boolExibirDadosAlterados = !this.bind.boolExibirDadosAlterados;
    },

    getLog() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Log", this.formFiltros)
        .then((objResult) => {
          this.loading = false;
          console.log("objResult", objResult);

          if (objResult.status == 200 && objResult.result) {
            this.objPagination = objResult.result;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {
              let item = {
                intId: objModelo._id,
                strNomeDatabase: objModelo.strNomeDatabase,
                strMensagem: objModelo.strMensagem,
                strEnderecoIp:objModelo.strEnderecoIp,
                strSessao: objModelo.strSessao,
                strTipo: objModelo.strTipo,
                strDadosAlterados:
                  "<pre>" + objModelo.strDadosAlterados + "</pre>",
                strDataCadastro: this.$utilidade.toDate(
                  objModelo.strDataCadastro
                ),
                buttons: [],
                _item: objModelo,
              };

              if (objModelo.strDadosAlterados ) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("logs"),
                  color: "warning",
                  title: "Visualizar dados alterados",
                  click: this.getDadosAlterados,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
